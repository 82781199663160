<template>
  <div>
    <div class="btBox">
      <div class="cont">
        <div class="contBox">
          <div class="logoimg">
            <img src="../../assets/img/logo.png" alt="" srcset="" />
            <div class="shu"></div>
            <div class="name">川田用户社区</div>
          </div>
          <div class="boxs">
            <div
              class="name"
              :class="listOn == index ? 'on' : ''"
              v-for="(item, index) in list"
              @click="listClick(index, item.path)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="contBox">
          <!-- <div class="inputBox">
            <div class="iconfont icon-search search"></div>
            <input
              type="text"
              placeholder="请搜索你感兴趣的内容"
              v-model="seachName"
              @keyup.enter="searchClick()"
            />
          </div>
          <div class="fbBox" @click="showClick">
            <div class="iconfont icon-xiugai xiugai"></div>
            <div class="name">发表</div>
          </div> -->
          <div class="loginBox" v-if="status == 401">
            <div class="name" @click="dlClick">登录</div>
            <div class="shu"></div>
            <div class="name" @click="zcshow = true">注册</div>
          </div>
          <div class="userBox" v-if="status != 401">
            <div class="img">
              <img :src="$imgUrl + user.avatar" alt="" />
            </div>
            <div class="name">{{ user.name ? user.name : user.nickname }}</div>
            <div class="tcBox">
              <div class="text" @click="userjump">个人中心</div>
              <div class="text" @click="szshow = true">信息设置</div>
              <div class="text" @click="loginOutClick">退出登录</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 55px"></div>
    <Zhuce v-if="zcshow" @show="zhuceShowJs" />
    <Shezhi v-if="szshow" @show="shezhiShowJs" />
  </div>
</template>
<script>
import Zhuce from "./zhuce.vue";
import Shezhi from "./shezhi.vue";

export default {
  components: {
    Zhuce,
    Shezhi,
  },
  data() {
    return {
      szshow: false,
      zcshow: false,
      dlshow: false,
      listOn: null,
      list: [
        {
          name: "论坛",
          path: "/index",
        },
        {
          name: "商城",
          path: "/shop",
        },
      ],
      user: {},
      seachName: "",
      status: 401,
    };
  },
  mounted() {
    for (let i = 0; i < this.list.length; i++) {
      const e = this.list[i];
      if (this.$route.fullPath == e.path) {
        this.listOn = i;
      }
    }
    let token = window.localStorage.getItem("token1");
    let member = window.localStorage.getItem("member");
    if (member) {
      this.status = 1;
      this.user = JSON.parse(member);
    }
    if (token) {
      this.get();
    }
  },
  activated() {},
  methods: {
    searchClick() {
      window.open("/chuantian/#/shopList?name=" + this.seachName);
    },
    loginOutClick() {
      this.$get("/web/login_out").then((res) => {
        if (res.status) {
          window.localStorage.removeItem("token1");
          window.localStorage.removeItem("member");
          this.$router.replace({
            path: "/login",
            query: {},
          });
        }
      });
    },
    shezhiShowJs(data) {
      this.szshow = false;
      if (data) {
        location.reload();
      }
    },
    get() {
      this.$get("/web/member", {}, true)
        .then((res) => {
          console.log(res);
          this.status = 1;
          window.localStorage.setItem("member", JSON.stringify(res.member));
          this.user = res.member;
        })
        .catch((err) => {
          this.status = err.status;
        });
    },
    listClick(i, path) {
      this.listOn = i;
      this.$router.push({
        path: path,
        query: {},
      });
    },
    zhuceShowJs() {
      this.zcshow = false;
    },
    userjump() {
      window.close();
      window.open("/chuantian/#/user/people");
      // this.$router.push({
      //   path: "/user/people",
      //   query: {},
      // });
    },
    dlClick() {
      this.$router.push({
        path: "/login",
        query: {},
      });
    },
    showClick() {
      let member = JSON.parse(window.localStorage.getItem("member"));
      if (!member) {
        this.$message.warning("请先登录！");
        return;
      }
      this.$emit("wenzhangShow", { title: "" });
    },
  },
};
</script>
<style lang="less" scoped>
.btBox {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 2px 2px 1px whitesmoke;
  font-family: PingFangSC-Regular, sans-serif;

  .cont {
    width: 1440px;
    margin: auto;
    display: flex;
    height: 55px;
    align-items: center;
    justify-content: space-between;

    .contBox {
      height: 55px;
      display: flex;
      align-items: center;
    }

    .logoimg {
      height: 30px;
      display: flex;
      align-items: center;

      img {
        height: 100%;
        width: auto;
      }

      .shu {
        width: 1px;
        height: 15px;
        margin: 0 10px;
        background-color: #999;
      }

      .name {
        font-size: 14px;
        font-weight: 700;
        color: #333;
      }
    }

    .boxs {
      margin-left: 40px;
      font-size: 0.14rm;
      display: flex;

      .name {
        margin: 0 25px;
        cursor: pointer;
      }

      .name:hover {
        color: #e5322c;
      }

      .on {
        color: #e5322c;
      }
    }

    .inputBox {
      margin-left: 38px;
      margin-right: 22px;
      width: 210px;
      height: 32px;
      border-radius: 18px;
      border: 1px solid #d9d9d9;
      background: none;
      font-size: 12px;
      padding: 0 10px 0 15px;
      color: #999;
      cursor: pointer;
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: start;
      justify-content: flex-start;

      .search {
        width: 20px;
      }

      input {
        height: 100%;
        width: 190px;
        box-sizing: border-box;
        padding-left: 5px;
        position: relative;
        background: unset;
        top: -1px;
      }
    }

    .fbBox {
      display: flex;
      align-items: center;
      height: 35px;
      border-radius: 35px;
      background-color: #e5322c;
      color: #fff;
      width: 90px;
      justify-content: center;
      cursor: pointer;

      .xiugai {
        font-size: 14px;
        margin-right: 5px;
      }
    }

    .loginBox {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #222;
      padding-left: 30px;
      .name {
        cursor: pointer;
      }
      .shu {
        width: 1px;
        height: 15px;
        margin: 0 5px;
        background-color: #999;
      }
    }
    .userBox {
      display: flex;
      align-items: center;
      padding-left: 30px;
      cursor: pointer;
      position: relative;
      height: 100%;
      .name {
        color: #222;
        margin-left: 10px;
      }
      .img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        overflow: hidden;
      }
      .tcBox {
        position: absolute;
        display: none;
        top: 53px;
        right: 10px;
        background-color: #fff;
        box-shadow: 0 0 4px 2px #ebebeb;
        width: 90px;
        .text {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          text-align: center;
        }
        .text:hover {
          background-color: #ebebeb;
        }
      }
    }
    .userBox:hover {
      .tcBox {
        display: block;
      }
    }
  }
}
</style>
